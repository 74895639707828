import { Box, Typography } from '@healthinal/ui';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { PidBlockListContainer } from '../../../settings/blocklist/PidBlockListContainer.tsx';

export const Route = createFileRoute('/_main/settings/pidblocklist')({
  component: PidBlockList,
});

function PidBlockList() {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography level="h3" paddingBottom={4}>
        {t('settings.block-list.title')}
      </Typography>
      <Typography level="body-sm" paddingBottom={4}>
        {t('settings.block-list.description')}
      </Typography>
      <PidBlockListContainer />
    </Box>
  );
}
