import { isAxiosError } from 'axios';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useGetGrantsByDataProjectGrantIdSuspense } from '../../api/generated.ts';
import { logout } from '../../auth/utils.ts';
import { hardNavigate } from '../../common/utils/hardNavigate.ts';
import { ErrorWithInterpretation, supportAction } from '../../errors/interpretError.ts';

export function validateRequiredSearchParams(search: Record<string, unknown>, ...parameters: string[]) {
  for (const parameter of parameters) {
    if (!search[parameter]) {
      throw new ErrorWithInterpretation({
        title: t('authorization.error.invalid-request.title'),
        description: t('authorization.error.invalid-request.description'),
        details: `Missing query parameter '${parameter}'`,
        shouldBeReported: false,
        actions: [supportAction()],
      });
    }
  }
}

export function useDataProjectGrants(dataProjectGrantId: string, redirectUri: string) {
  const { t } = useTranslation();
  const result = useGetGrantsByDataProjectGrantIdSuspense(dataProjectGrantId, {
    query: {
      interpretError: (error) => {
        if (isAxiosError(error)) {
          if (error.response?.status === 400 || error.response?.status === 404) {
            return {
              title: t('authorization.error.invalid-request.title'),
              description: t('authorization.error.invalid-request.description'),
              details: `Invalid query parameter 'installation_id' (${error.response.status})`,
              shouldBeReported: false,
              actions: [supportAction()],
            };
          }
          if (error.response?.status === 403) {
            return {
              title: t('authorization.error.forbidden.title'),
              description: t('authorization.error.forbidden.description'),
              shouldBeReported: false,
              actions: [
                {
                  label: t('switch-user'),
                  onClick: () => logout({ saveContinueHref: true }),
                },
                {
                  label: t('cancel'),
                  onClick: () => redirectToDataProject({ dataProjectGrantId, redirectUri, error: 'cancelled' }),
                },
              ],
            };
          }
        }
        return undefined;
      },
    },
  });

  if (!result.data.dataProjectGranted) {
    throw new ErrorWithInterpretation({
      title: t('authorization.error.revoked-grant.title'),
      description: t('authorization.error.revoked-grant.description'),
      shouldBeReported: false,
      actions: [
        {
          label: t('continue-to-data-project'),
          onClick: () => redirectToDataProject({ dataProjectId: result.data.dataProjectId, redirectUri }),
        },
      ],
    });
  }

  return result;
}

type RedirectToDataProjectOptions = (
  | { dataProjectId: string; dataProjectGrantId?: never }
  | { dataProjectId?: never; dataProjectGrantId: string }
) & {
  redirectUri: string;
  error?: string;
  state?: string;
};

export function redirectToDataProject({
  dataProjectId,
  dataProjectGrantId,
  redirectUri,
  error,
  state,
}: RedirectToDataProjectOptions) {
  const redirect = new URL(
    dataProjectId !== undefined
      ? `/portal-api/data-projects/${encodeURIComponent(dataProjectId)}/redirect`
      : `/portal-api/data-projects/grants/${encodeURIComponent(dataProjectGrantId)}/redirect`,
    window.location.href,
  );
  redirect.searchParams.append('redirectUri', redirectUri);
  if (error) {
    redirect.searchParams.append('error', error);
  }
  if (state) {
    redirect.searchParams.append('state', state);
  }
  hardNavigate(redirect);
}
