import { Stack, Typography } from '@healthinal/ui';
import { ReactNode } from 'react';

interface AuthorizationStatusPanelProps {
  decoration: ReactNode;
  title: string;
  body: string;
  children?: ReactNode;
}

export function AuthorizationStatusPanel({ decoration, title, body, children }: AuthorizationStatusPanelProps) {
  return (
    <Stack gap={4} alignItems="center">
      <Stack height={64} justifyContent="center">
        {decoration}
      </Stack>
      <Stack
        textAlign="center"
        gap={2}
        marginTop={-0.5} // so the title lines up nicely with the title in AuthDataProjectPanel
      >
        <Typography level="title-lg">{title}</Typography>
        <Typography level="body-md" textColor="text.secondary">
          {body}
        </Typography>
      </Stack>
      {children}
    </Stack>
  );
}
