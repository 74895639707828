import { Stack } from '@healthinal/ui';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import { Suspense } from 'react';
import { Header } from '../common/components/Header.tsx';
import { SplashScreen } from '../common/components/SplashScreen.tsx';
import { ErrorBoundary } from '../errors/ErrorBoundary.tsx';
import { contentWidth } from '../theme.ts';

export const Route = createFileRoute('/_main')({
  component: MainLayout,
});

export const mainLayoutContentMarginY = 40;

function MainLayout() {
  return (
    <>
      <Header />
      <Stack maxWidth={contentWidth} width="100%" marginY={mainLayoutContentMarginY / 8} marginX="auto">
        <Suspense fallback={<SplashScreen />}>
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </Suspense>
      </Stack>
    </>
  );
}
