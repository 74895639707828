import { LinearProgress, styled, Typography } from '@healthinal/ui';
import { keyframes } from '@mui/system';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { PortalDataProject } from '../api/generated.ts';
import { HeurekaSpinner } from '../common/components/HeurekaSpinner.tsx';
import { heurekaBlue, heurekaViolet } from '../theme.ts';
import { AuthorizationStatusPanel } from './AuthorizationStatusPanel.tsx';

export interface AuthorizationSyncProps {
  dataProject: PortalDataProject;
  onFinish: () => void;
}

const waitSeconds = 15;

export function AuthorizationSync({ dataProject, onFinish }: AuthorizationSyncProps) {
  const { t } = useTranslation();

  // uses a ref so the timeout callback has access to the latest function reference
  const onFinishRef = useRef(onFinish);
  onFinishRef.current = onFinish;

  useEffect(() => {
    const timeout = setTimeout(() => onFinishRef.current(), waitSeconds * 1000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <AuthorizationStatusPanel
        decoration={<HeurekaSpinner size="md" />}
        title={t('authorization.sync.title')}
        body={t('authorization.sync.body', { dataProject: dataProject.name })}>
        <ProgressBar size="lg" determinate />
      </AuthorizationStatusPanel>

      <Typography marginTop="auto" level="body-sm" textAlign="center">
        {t('authorization.sync.hint')}
      </Typography>
    </>
  );
}

const progressBarAnimation = keyframes({
  '0%': {
    inlineSize: 0,
  },
  '100%': {
    inlineSize: '100%',
  },
});

const ProgressBar = styled(LinearProgress)({
  marginTop: 3,
  width: '88%',
  '::before': {
    // Since the progress bar should take a fixed amount of time, we can use css to animate it smoothly.
    // The alternative would be updating the value prop every few milliseconds which is not as clean.
    animation: `${progressBarAnimation} ${waitSeconds}s linear forwards`,
    background: `linear-gradient(to right, ${heurekaBlue} 0, ${heurekaViolet} 320px)`,
  },
});
