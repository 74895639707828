import { Link, Stack } from '@healthinal/ui';
import { PortalDataProject } from '../api/generated.ts';

interface DataProjectSupportLinksProps {
  dataProject: PortalDataProject;
}

export function DataProjectSupportLinks({ dataProject }: DataProjectSupportLinksProps) {
  return (
    <Stack direction="row" flexWrap="wrap" gap={1}>
      <Link level="body-sm" href={`mailto:${dataProject.primaryContactEmail}`}>
        {dataProject.primaryContactEmail}
      </Link>
      {dataProject.primaryContactPhone && (
        <Link level="body-sm" href={`tel:${dataProject.primaryContactPhone}`}>
          {dataProject.primaryContactPhone}
        </Link>
      )}
    </Stack>
  );
}
