import { Link, Stack, Textarea, Typography } from '@healthinal/ui';
import { Trans, useTranslation } from 'react-i18next';

interface RevokeCommentProps {
  value: string;
  onValueChange: (value: string) => void;
}

const MAX_LENGTH = 400;
const SUPPORT_EMAIL = 'support@heureka.health';

export function RevokeComment({ value, onValueChange }: RevokeCommentProps) {
  const { t } = useTranslation();
  return (
    <Stack gap={1}>
      <Textarea
        placeholder={t('authorization.revoke.comment.label')}
        size="sm"
        minRows={3}
        maxRows={6}
        value={value}
        onChange={(event) => onValueChange(event.target.value.slice(0, MAX_LENGTH))}
      />
      {value.length >= MAX_LENGTH && (
        <>
          <Typography level="body-sm" color="warning">
            {t('authorization.revoke.comment.max-length-warning')}
          </Typography>
          <Typography level="body-sm">
            <Trans
              i18nKey="authorization.revoke.comment.support"
              components={{ emailLink: <Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link> }}
            />
          </Typography>
        </>
      )}
    </Stack>
  );
}
