import { Stack, Switch, Typography } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';
import { PortalScopeRequest } from '../api/generated.ts';
import { ResourceIcon } from '../common/components/ResourceIcon.tsx';

interface PermissionScopeProps {
  request: PortalScopeRequest;
  granted?: boolean;
  onGrantedChange?: (granted: boolean) => void;
  disabled?: boolean;
  readOnly?: boolean;
}

export function PermissionScope({ request, granted, onGrantedChange, disabled, readOnly }: PermissionScopeProps) {
  const { t } = useTranslation();
  return (
    <Stack component="label" direction="row" gap={1} sx={{ cursor: request.optional ? 'pointer' : undefined }}>
      <ResourceIcon type={request.type} size="sm" />
      <Stack flex={1} gap={0.5}>
        <Typography level="title-sm">{t(`resource-type.read.${request.type}`)}</Typography>
        <Typography level="body-sm">{request.resourceString}</Typography>
      </Stack>
      {request.optional && !readOnly && (
        <Switch
          sx={{ alignSelf: 'flex-start' }}
          checked={granted}
          onChange={(event) => onGrantedChange?.(event.target.checked)}
          disabled={disabled}
        />
      )}
    </Stack>
  );
}
