import { Tooltip, Typography } from '@healthinal/ui';
import { useMemo } from 'react';
import { RequestContextDto, useReadDataProjectsSuspense } from '../api/generated.ts';
import { DataProjectTag } from '../data-projects/DataProjectTag.tsx';
import { formatTime, formatTimestamp } from '../i18n/date.ts';
import { AccessLogUser } from './AccessLogUser.tsx';

interface AccessLogRowProps {
  requestContext: RequestContextDto;
}

export function AccessLogRow({ requestContext }: AccessLogRowProps) {
  const { data: dataProjects } = useReadDataProjectsSuspense();
  const dataProject = useMemo(
    () => dataProjects.find((p) => p.id === requestContext.dataProjectId),
    [dataProjects, requestContext.dataProjectId],
  );
  return (
    <tr>
      <td>
        <Tooltip title={formatTimestamp(requestContext.oldestTimestamp)}>
          <span>{formatTime(requestContext.oldestTimestamp)}</span>
        </Tooltip>
      </td>
      <td>{dataProject && <DataProjectTag dataProject={dataProject} />}</td>
      <td>{requestContext.requestContextType}</td>
      <td>
        <AccessLogUser user={requestContext.user}></AccessLogUser>
      </td>
      <td>
        <Tooltip title={requestContext.patientId}>
          <Typography noWrap>{requestContext.patientId}</Typography>
        </Tooltip>
      </td>
    </tr>
  );
}
