import { Card, Stack, styled } from '@healthinal/ui';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import { Suspense } from 'react';
import { SplashScreen } from '../common/components/SplashScreen.tsx';
import { ErrorBoundary } from '../errors/ErrorBoundary.tsx';
import { heurekaViolet, theme } from '../theme.ts';

export const Route = createFileRoute('/authorization')({
  component: AuthorizationLayout,
});

function AuthorizationLayout() {
  return (
    <Backdrop>
      <ContentCard variant="plain">
        <Suspense fallback={<SplashScreen />}>
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </Suspense>
      </ContentCard>
    </Backdrop>
  );
}

const Backdrop = styled('div')({
  background: `linear-gradient(-24deg, ${theme.vars.palette.primary[700]}, ${heurekaViolet})`,
  width: '100vw',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ContentCard = styled(Card)({
  width: '100%',
  maxWidth: 880,
  minHeight: 560,
  padding: 0,
});

export const AuthorizationColumn = styled(Stack)({
  width: '50%',
  padding: 40,
  gap: 40,
});

export const LeftAuthorizationColumn = styled(AuthorizationColumn)({
  background: theme.vars.palette.background.body,
  borderBottomLeftRadius: theme.vars.radius.md,
  borderTopLeftRadius: theme.vars.radius.md,
});
