import { Typography } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';
import { RequestContextUserDto } from '../api/generated.ts';

interface AccessLogUserProps {
  user?: RequestContextUserDto;
}

export function AccessLogUser({ user }: AccessLogUserProps) {
  const { t } = useTranslation();

  if (!user) {
    return <Typography textColor="text.tertiary">{t(`access-log.system-role`)}</Typography>;
  }

  return (
    <>
      {user.name}
      <Typography display="inline" textColor="text.tertiary">
        , {user.role}
      </Typography>
    </>
  );
}
