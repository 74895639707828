import { useWhoamiSuspense } from '../api/generated.ts';

export function useHealthcareProviderId() {
  const { data: whoami } = useWhoamiSuspense();
  const healthcareProviderId = whoami.healthcareProviderId;
  if (!healthcareProviderId) {
    throw new NoHealthcareProviderError();
  }
  return healthcareProviderId;
}

export class NoHealthcareProviderError extends Error {
  constructor() {
    super('User has no healthcare provider assigned');
  }
}
