import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { ErrorView } from '../../errors/ErrorView.tsx';

export interface Search {
  invalid_redirect_uri: string;
}

export const Route = createFileRoute('/authorization/invalid-redirect-uri')({
  component: InvalidRedirectUriErrorPage,
  validateSearch: (search: Record<string, unknown>): Search => {
    return {
      invalid_redirect_uri: String(search.invalid_redirect_uri),
    };
  },
});

function InvalidRedirectUriErrorPage() {
  const { t } = useTranslation();
  const search = Route.useSearch();
  return (
    <ErrorView
      title={t('authorization.error.invalid-redirect-uri.title')}
      description={t('authorization.error.invalid-redirect-uri.description')}
      // Keep the details untranslated to indicate to the user that these are technical details.
      details={`Invalid redirect URI: ${search.invalid_redirect_uri}`}
      shouldBeReported={false}
    />
  );
}
