import { Box, Icons, Stack, StackProps, styled, Tooltip, Typography, TypographyProps } from '@healthinal/ui';
import { createLink } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { PortalDataProject, useWhoamiSuspense } from '../../api/generated.ts';
import { heurekaViolet, theme } from '../../theme.ts';
import { flowAnimationStyles } from './FlowAnimation.tsx';
import { HeurekaConnectorCard } from './HeurekaConnectorCard.tsx';
import { PisCard } from './PisCard.tsx';

interface DataFlowVisualizationProps {
  grantedDataProjects: PortalDataProject[];
}

export function DataFlowVisualization({ grantedDataProjects }: DataFlowVisualizationProps) {
  const { data: whoami } = useWhoamiSuspense();
  const { t } = useTranslation();

  return (
    <>
      <Layout>
        <PracticeArea gridColumn="1" gridRow="1 / 3" />

        <AreaTitle gridColumn="1" gridRow="1" textColor={heurekaViolet}>
          {whoami.organization}
        </AreaTitle>
        <HorizontalStack gridColumn="1" gridRow="2" paddingY={4}>
          <Spacer />
          <PisCard />
          <Line />
          <HeurekaConnectorCard />
          <Line />
        </HorizontalStack>

        <AreaTitle gridColumn="3" gridRow="1">
          {t('data-projects.title')}
        </AreaTitle>
        <HorizontalStack gridColumn="2 / 4" gridRow="2" paddingY={4}>
          <HorizontalStack
            // make sure the icons are centered, even though this element also stretches over the gap between the two areas
            flexBasis={areaGap}
            flexGrow={1}
            // fade out on the right
            sx={{ maskImage: 'linear-gradient(to right, black 64%, transparent)' }}>
            <Line />
            {grantedDataProjects.length > 1 && <LineFork />}
          </HorizontalStack>
          <DataProjects grantedDataProjects={grantedDataProjects} />
          <Spacer />
        </HorizontalStack>
      </Layout>
    </>
  );
}

const areaGap = 16;

const Layout = styled('div')({
  display: 'grid',
  gridTemplateColumns: `minmax(0, 1fr) ${areaGap}px minmax(0, 1fr)`,
});

const PracticeArea = styled(Box)({
  background: `color-mix(in srgb, ${heurekaViolet} 12%, white)`,
  borderRadius: theme.radius.xl,
});

const AreaTitle = (typographyProps: TypographyProps) => (
  <Typography level="title-md" textAlign="center" paddingTop={2} gridRow={1} {...typographyProps} />
);

const HorizontalStack = (stackProps: StackProps) => <Stack direction="row" alignItems="center" {...stackProps} />;

const Spacer = styled('div')({
  flex: 1,
});

const Line = styled('div')({
  ...flowAnimationStyles,
  flex: 1,
  height: 2,
});

const LineFork = styled('div')({
  ...flowAnimationStyles,
  clipPath: `path('M92.2406 25C75.1652 25 58.6943 31.3205 46.0023 42.7433L44.8324 43.7962C35.4183 52.2689 23.8598 57.8464 11.5352 60C23.8598 62.1536 35.4183 67.7311 44.8324 76.2038L46.0023 77.2567C58.6943 88.6795 75.1652 95 92.2406 95H120V97H92.2406C74.6712 97 57.7236 90.4966 44.6644 78.7433L43.4945 77.6904C31.5556 66.9454 16.0621 61 0 61V59C16.0621 59 31.5556 53.0546 43.4945 42.3096L44.6644 41.2567C57.7236 29.5034 74.6712 23 92.2406 23H120V25H92.2406Z')`,
  // viewbox of the clippath
  height: 120,
  width: 120,
});

const dataProjectIconSize = 56;

function DataProjects({ grantedDataProjects }: DataFlowVisualizationProps) {
  const { t } = useTranslation();
  const height = grantedDataProjects.length > 1 ? 2 * dataProjectIconSize + 16 : dataProjectIconSize;
  return (
    <Stack flexWrap="wrap" height={height} gap={2}>
      {grantedDataProjects.map((dataProject) => (
        <Tooltip key={dataProject.id} title={t('manage-data-project', { dataProject: dataProject.name })}>
          <IconLink to="/apps/$id" params={{ id: dataProject.id }}>
            <img src={dataProject.logo} alt="" width={dataProjectIconSize} height={dataProjectIconSize} />
          </IconLink>
        </Tooltip>
      ))}
      <Tooltip title={t('discover-data-projects')}>
        <IconLink to="/apps">
          <DashedContainer>
            <Icons.Add />
          </DashedContainer>
        </IconLink>
      </Tooltip>
    </Stack>
  );
}

const IconLink = createLink(
  styled('a')({
    display: 'flex',
    transition: 'transform 200ms',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  }),
);

const DashedContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: dataProjectIconSize,
  height: dataProjectIconSize,
  border: `dashed 2px ${theme.vars.palette.divider}`,
  borderRadius: theme.vars.radius.lg,
});
