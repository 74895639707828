import axios, { AxiosError, AxiosRequestConfig } from 'axios';

// custom request function to return only the data for successful responses
export const request = async <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
  const response = await axios.request({
    paramsSerializer: {
      indexes: null, // serialize lists as ?list=1&list=2
    },
    ...config,
    ...options,
  });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return response.data;
};

export type ErrorType<Error> = AxiosError<Error>;
export type BodyType<BodyData> = BodyData;
