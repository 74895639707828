import { Stack, Typography } from '@healthinal/ui';
import { PortalDataProject } from '../api/generated.ts';

interface DataProjectTagProps {
  dataProject: PortalDataProject;
}

export function DataProjectTag({ dataProject }: DataProjectTagProps) {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <img src={dataProject.logo} alt="" width={16} height={16} />
      <Typography noWrap>{dataProject.name}</Typography>
    </Stack>
  );
}
