import { createContext, ReactNode, useContext, useState } from 'react';
import { useWhoami } from '../../api/generated.ts';
import { HubspotFormDialog } from './HubspotForm.tsx';

const SupportFormContext = createContext<(() => void) | null>(null);

interface SupportFormProviderProps {
  children: ReactNode;
}

export function SupportFormProvider({ children }: SupportFormProviderProps) {
  const [open, setOpen] = useState(false);
  const { data: whoami } = useWhoami({ query: { throwOnError: false } });
  return (
    <SupportFormContext.Provider value={() => setOpen(true)}>
      {children}
      {open && (
        <HubspotFormDialog
          onClose={() => setOpen(false)}
          formId="42130ece-fc9b-4629-b4d2-3247840770e4"
          formDefaultValues={
            whoami && {
              email: whoami.email,
              hrka_name: whoami.name,
              hrka_hcp: whoami.organization,
            }
          }
        />
      )}
    </SupportFormContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export const useSupportForm = () => useContext(SupportFormContext)!;
