import { Button, Modal, ModalClose, ModalDialog, ModalProps, Stack } from '@healthinal/ui';
import { useEffect, useId, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface HubspotFormDialogProps extends Omit<ModalProps, 'children' | 'open'>, HubspotFormProps {
  onClose: () => void;
}

export function HubspotFormDialog({ formId, formDefaultValues, ...modalProps }: HubspotFormDialogProps) {
  const [submitted, setSubmitted] = useState(false);
  const { t } = useTranslation();
  return (
    <Modal {...modalProps} open>
      <ModalDialog minWidth="sm">
        <ModalClose />
        <Stack overflow="auto" gap={2}>
          <HubspotForm formId={formId} formDefaultValues={formDefaultValues} onSubmitted={() => setSubmitted(true)} />
          {submitted && <Button onClick={modalProps.onClose}>{t('close')}</Button>}
        </Stack>
      </ModalDialog>
    </Modal>
  );
}

declare global {
  interface Window {
    hbspt?: {
      forms: {
        create: (options: unknown) => void;
      };
    };
  }
}

const hubspotTenantId = '20398904';

interface HubspotFormProps {
  formId: string;
  formDefaultValues?: Record<string, string | undefined | null>;
  onSubmitted?: () => void;
}

export function HubspotForm({ formId, formDefaultValues, onSubmitted }: HubspotFormProps) {
  const id = useId();

  const onSubmittedRef = useRef(onSubmitted);
  onSubmittedRef.current = onSubmitted; // allow onFormSubmitted callback to use the latest function reference

  useEffect(() => {
    window.hbspt?.forms.create({
      portalId: hubspotTenantId,
      formId,
      target: `#${CSS.escape(id)}`,
      onFormSubmitted: () => {
        onSubmittedRef.current?.();
      },
      onFormReady: (form: HTMLFormElement) => {
        if (!formDefaultValues) return;
        for (const [key, value] of Object.entries(formDefaultValues)) {
          const input = form.querySelector<HTMLInputElement>(`input[name="${key}"]`);
          if (input && value) {
            input.value = value;
            input.dispatchEvent(new Event('input'));
          }
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- intentionally doesn't react to changes in formDefaultValues
  }, [formId, id]);

  return <div id={id} />;
}
