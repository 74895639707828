import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import {
  getGetGrantsOfDataProjectQueryKey,
  PortalDataProjectWithScopesDto,
  useGetGrantsOfDataProjectSuspense,
  useSetGrantsOfDataProject,
} from '../api/generated.ts';
import { useHealthcareProviderId } from '../user/useHealthcareProviderId.ts';

export function useSetGrants(
  dataProject: PortalDataProjectWithScopesDto,
  options?: Parameters<typeof useSetGrantsOfDataProject>[0],
) {
  const healthcareProviderId = useHealthcareProviderId();
  const { data: grants } = useGetGrantsOfDataProjectSuspense(dataProject.id, { healthcareProviderId });

  const initialGrantedScopeRequestIds = new Set<string>(
    grants.grantedScopeRequestIds.length > 0
      ? grants.grantedScopeRequestIds
      : dataProject.scopes.map((request) => request.id), // by default, all requests are granted
  );

  const [grantedScopeRequestIds, setGrantedScopeRequestIds] = useState(initialGrantedScopeRequestIds);

  const queryClient = useQueryClient();
  const mutation = useSetGrantsOfDataProject({
    ...options,
    mutation: {
      ...options?.mutation,
      onSuccess: (...args) => {
        void queryClient.invalidateQueries({
          queryKey: getGetGrantsOfDataProjectQueryKey(dataProject.id, { healthcareProviderId }),
        });
        options?.mutation?.onSuccess?.(...args);
      },
    },
  });

  const variables = {
    id: dataProject.id,
    data: Array.from(grantedScopeRequestIds),
    params: { healthcareProviderId },
  };

  return {
    ...mutation,
    mutateAsync: () => mutation.mutateAsync(variables),
    mutate: () => mutation.mutate(variables),
    grantedScopeRequestIds,
    setGrantedScopeRequestIds,
    hasChanges: !setsAreEqual(grantedScopeRequestIds, initialGrantedScopeRequestIds),
    resetChanges: () => setGrantedScopeRequestIds(initialGrantedScopeRequestIds),
  };
}

function setsAreEqual<T>(a: Set<T>, b: Set<T>) {
  return a.size === b.size && Array.from(a).every((aItem) => b.has(aItem));
}
