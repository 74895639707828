import { Card, Icons, Stack, StackProps, styled, Tooltip, TooltipProps } from '@healthinal/ui';
import { keyframes } from '@mui/system';
import { ReactNode } from 'react';
import { heurekaViolet, theme } from '../../theme.ts';
import { flowAnimationStyles } from './FlowAnimation.tsx';

interface DataFlowCardProps extends StackProps {
  infoCardContent: ReactNode;
}

export function DataFlowCard({ infoCardContent, ...stackProps }: DataFlowCardProps) {
  return (
    <Tooltip
      title={<InfoCard>{infoCardContent}</InfoCard>}
      slotProps={
        {
          root: {
            sx: {
              // remove any styling from the tooltip root
              background: 'none',
              boxShadow: 'none',
              padding: 0,
            },
          },
        } as TooltipProps['slotProps']
      }>
      <AnimatedBorder>
        <Content {...stackProps} />
      </AnimatedBorder>
    </Tooltip>
  );
}

export const DataFlowInfoIcon = styled(Icons.InfoOutlined)({
  '--Icon-fontSize': '16px',
  color: heurekaViolet,
  opacity: 0.56,
});

const infoCardAnimation = keyframes({
  '0%': {
    opacity: 0,
    transform: 'scale(0.8)',
  },
  '100%': {
    opacity: 1,
    transform: 'scale(1)',
  },
});

const InfoCard = styled(Card)({
  maxWidth: 320,
  '--Card-padding': '24px',
  marginTop: '-6px', // reduce space between anchor and tooltip
  boxShadow: theme.vars.shadow.xl,
  animation: `${infoCardAnimation} 200ms`,
  transformOrigin: 'top',
});

const AnimatedBorder = styled('div')({
  padding: '2px',
  borderRadius: `calc(${theme.vars.radius.md} + 2px)`,
  ...flowAnimationStyles,
});

const Content = styled(Stack)({
  background: theme.vars.palette.background.surface,
  padding: '16px 24px',
  borderRadius: theme.vars.radius.md,
  alignItems: 'center',
});
