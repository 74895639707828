import { Icons, SvgIconProps } from '@healthinal/ui';
import { ComponentType } from 'react';
import { PortalScopeRequestType } from '../../api/generated.ts';

const icons: Record<PortalScopeRequestType, ComponentType> = {
  PATIENT: Icons.Person,
  MEDICATION_STATEMENT: Icons.Vaccines,
  OBSERVATION: Icons.Assessment,
  CONDITION: Icons.Flag,
  APPOINTMENT: Icons.Today,
};

interface ResourceIconProps extends SvgIconProps {
  type: PortalScopeRequestType;
}

export function ResourceIcon({ type, ...iconProps }: ResourceIconProps) {
  const Icon = icons[type] ?? Icons.QuestionMark;
  return <Icon {...iconProps} />;
}
