import { styled, Table } from '@healthinal/ui';
import { theme } from '../../theme.ts';

export const tableRowHeight = 48;

export const StyledTable = styled(Table)({
  '--Table-headerUnderlineThickness': '1px',
  '--TableCell-footBackground': 'none',

  'td, th': {
    height: tableRowHeight,
    '&:first-of-type': {
      paddingLeft: 16,
    },
    '&:last-of-type': {
      paddingRight: 16,

      '> button:last-child': {
        marginRight: -8, // buttons look better with less spacing at the end
      },
    },
  },

  'thead th': {
    verticalAlign: 'middle',
    color: theme.vars.palette.text.tertiary,
  },
});

export const StickyTableRow = styled('tr')({
  position: 'sticky',
  top: 0,
  zIndex: 1,
});
