import { Button, Card, Stack, Typography } from '@healthinal/ui';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { useWhoami } from '../api/generated.ts';
import { logout } from '../auth/utils.ts';
import { AuthHeader } from '../common/components/AuthHeader.tsx';
import { WhatIsHeureka } from '../common/components/WhatIsHeureka.tsx';
import { hardNavigate } from '../common/utils/hardNavigate.ts';
import { contentWidth } from '../theme.ts';

export interface Search {
  name: string;
}

export const Route = createFileRoute('/unknown-user')({
  component: UnknownUser,
  validateSearch: (search: Record<string, unknown>): Search => ({ name: String(search.name ?? '') }),
});

function UnknownUser() {
  const { t } = useTranslation();
  const search = Route.useSearch();
  const { isError, isLoading } = useWhoami({ query: { throwOnError: false } });

  if (!isLoading && !isError) {
    hardNavigate('/');
  }

  return (
    <>
      <AuthHeader />
      <Stack gap={8} direction="row" alignSelf="center" alignItems="center" width={contentWidth}>
        <Card sx={{ padding: '32px', minWidth: 400, maxWidth: 400 }}>
          <Stack gap={4}>
            <Typography level="title-lg" textAlign="center">
              {t('welcome.greeting', { user: search.name })}
            </Typography>
            <Typography level="body-md">{t('unknown-user.installation-needed')}</Typography>
            <Stack width="100%" gap={2}>
              <Button component="a" href="https://heureka.health/sign-up">
                {t('request-installation')}
              </Button>
              <Button variant="soft" color="neutral" onClick={() => logout()}>
                {t('logout')}
              </Button>
            </Stack>
            <Stack gap={1}>
              <Typography level="body-sm">{t('unknown-user.already-installed')}</Typography>
              <Typography level="body-sm">{t('unknown-user.contact-administrator')}</Typography>
            </Stack>
          </Stack>
        </Card>
        <WhatIsHeureka />
      </Stack>
    </>
  );
}
