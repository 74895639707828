import { Button, Card, Icons, Link, MenuButton, Stack, styled, Typography } from '@healthinal/ui';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { PortalDataProjectWithScopesDto, useWhoamiSuspense } from '../api/generated.ts';
import { HelpMenu } from '../common/components/HelpMenu.tsx';
import { PisBadge } from '../pis/PisBadge.tsx';
import { theme } from '../theme.ts';
import { logout } from './utils.ts';

interface AuthDataProjectPanelProps {
  dataProject: PortalDataProjectWithScopesDto;
  title: ReactNode;
  prompt: ReactNode;
  hint?: ReactNode;
}

export function AuthDataProjectPanel({ dataProject, title, prompt, hint }: AuthDataProjectPanelProps) {
  const { t } = useTranslation();
  const { data: whoami } = useWhoamiSuspense();
  return (
    <>
      <Stack alignItems="center" gap={3}>
        <Stack direction="row" alignItems="center" gap={2}>
          <img src={dataProject.logo} alt="" height={64} />
          <DottedLine />
          <img src="/favicon.svg" alt="" height={48} />
        </Stack>
        <Stack gap={0.5} alignItems="center">
          <Typography level="h4">{dataProject.name}</Typography>
          <Typography level="body-md" textColor="text.tertiary">
            {title}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={3}>
        <Typography level="body-md">{prompt}</Typography>
        <Card orientation="horizontal">
          <Typography level="title-md" flex={1}>
            {whoami.organization}
          </Typography>
          {whoami.pis && <PisBadge pis={whoami.pis.type} />}
        </Card>
        {hint && <Typography level="body-sm">{hint}</Typography>}
      </Stack>
      <Stack
        direction="row"
        gap={1}
        justifyContent="space-between"
        minHeight={36} // align height with buttons of right side panel
        mt="auto">
        <Stack direction="row" gap={1} alignItems="center" justifyContent="center">
          <Icons.PersonOutlined size="sm" />
          <Typography level="body-sm">{whoami.name}</Typography>
          <Link
            onClick={() => {
              logout({ saveContinueHref: true });
            }}
            level="body-sm">
            {t('authorization.not-you')}
          </Link>
        </Stack>
        <HelpMenu>
          <MenuButton
            slots={{ root: Button }}
            slotProps={{
              root: {
                endDecorator: <Icons.HelpOutlineOutlined />,
                variant: 'plain',
                size: 'sm',
              },
            }}>
            {t('help')}
          </MenuButton>
        </HelpMenu>
      </Stack>
    </>
  );
}

const DottedLine = styled('div')({
  borderTop: `dotted 4px ${theme.vars.palette.divider}`,
  width: 16,
});
