import { Button, Card, Typography } from '@healthinal/ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PortalDataProject } from '../api/generated.ts';
import { RevokeGrantDialog } from './RevokeGrantDialog.tsx';

interface RevokeGrantCardProps {
  dataProject: PortalDataProject;
}

export function RevokeGrantCard({ dataProject }: RevokeGrantCardProps) {
  const { t } = useTranslation();
  const [revokeOpen, setRevokeOpen] = useState(false);
  return (
    <Card size="lg">
      <Typography level="title-sm">{t('authorization.revoke.action')}</Typography>
      <Typography level="body-sm">
        {t('authorization.revoke.description', { dataProject: dataProject.name })}
      </Typography>
      <Button
        color="danger"
        variant="soft"
        size="sm"
        sx={{ alignSelf: 'flex-end' }}
        onClick={() => setRevokeOpen(true)}>
        {t('authorization.revoke.action')}
      </Button>
      {revokeOpen && <RevokeGrantDialog open onClose={() => setRevokeOpen(false)} dataProject={dataProject} />}
    </Card>
  );
}
