import { Stack, styled } from '@healthinal/ui';
import { Link, LinkProps, useMatchRoute } from '@tanstack/react-router';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../theme.ts';

type NavigationConfigItem = Required<Pick<LinkProps, 'to'>> & { label: string };

export function Navigation() {
  const matchRoute = useMatchRoute();
  const { t } = useTranslation();

  const [activeIndicatorLeft, setActiveIndicatorLeft] = useState<number | undefined>();
  const [activeIndicatorWidth, setActiveIndicatorWidth] = useState<number | undefined>();

  const items: NavigationConfigItem[] = [
    {
      to: '/',
      label: t('nav.index'),
    },
    {
      to: '/apps',
      label: t('nav.data-projects'),
    },
    {
      to: '/access-log',
      label: t('nav.access-log'),
    },
  ];

  let someItemIsActive = false;
  const links = items.map((item) => {
    const isActive = !!matchRoute({ to: item.to, fuzzy: true });
    someItemIsActive ||= isActive;
    return (
      <NavigationLink
        ref={(element) => {
          if (isActive && element) {
            setActiveIndicatorLeft(element.offsetLeft);
            setActiveIndicatorWidth(element.offsetWidth);
          }
        }}
        key={item.to}
        to={item.to}>
        {item.label}
      </NavigationLink>
    );
  });

  return (
    <Stack direction="row" gap={4} position="relative">
      {links}
      <AnimatePresence>
        {someItemIsActive && activeIndicatorWidth !== undefined && activeIndicatorLeft !== undefined && (
          <ActiveIndicator width={activeIndicatorWidth} left={activeIndicatorLeft} />
        )}
      </AnimatePresence>
    </Stack>
  );
}

const NavigationLink = styled(Link)({
  ...theme.typography['title-md'],
  color: theme.vars.palette.primary.plainColor,
  textDecoration: 'none',
  padding: 4,
  '&:hover': {
    color: theme.vars.palette.primary.softColor,
  },
});

interface ActiveIndicatorProps {
  left: number;
  width: number;
}

function ActiveIndicator({ left, width }: ActiveIndicatorProps) {
  return (
    <motion.div
      style={{
        position: 'absolute',
        bottom: 0,
        height: 2,
        background: theme.vars.palette.primary.plainColor,
      }}
      variants={{
        hidden: { left: left + width / 2, width: 0 },
        visible: { left, width },
      }}
      initial="hidden"
      animate="visible"
      exit="hidden"
      transition={{ duration: 0.2 }}
    />
  );
}
