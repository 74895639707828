import { styled } from '@healthinal/ui';
import { PortalDataProject } from '../api/generated.ts';
import { DataProjectCard, DataProjectCardProps } from './DataProjectCard.tsx';

interface DataProjectsGridProps extends Pick<DataProjectCardProps, 'showDescription' | 'showGrantState'> {
  dataProjects: PortalDataProject[];
}

export function DataProjectsGrid({ dataProjects, ...cardProps }: DataProjectsGridProps) {
  return (
    <Grid>
      {dataProjects.map((dataProject) => (
        <DataProjectCard component="li" key={dataProject.id} dataProject={dataProject} {...cardProps} />
      ))}
    </Grid>
  );
}

const Grid = styled('ul')({
  display: 'grid',
  gap: 24,
  gridTemplateColumns: '1fr 1fr',
  padding: 0,
  margin: 0,
});
