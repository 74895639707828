import { Card, Stack, Typography } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';
import { PortalDataProjectWithScopesDto } from '../api/generated.ts';

interface DataUsageCardProps {
  dataProject: PortalDataProjectWithScopesDto;
}

export function DataUsageCard({ dataProject }: DataUsageCardProps) {
  const { t } = useTranslation();
  return (
    <Card size="lg">
      <Stack gap={4}>
        <DataUsageChapter title={t('data-usage-title')} text={dataProject.dataUsage} />
        <DataUsageChapter title={t('data-visibility-title')} text={dataProject.dataVisibility} />
        <DataUsageChapter title={t('patient-consent-title')} text={dataProject.patientConsent} />
      </Stack>
    </Card>
  );
}

interface DataUsageChapterProps {
  title: string;
  text?: string;
}

function DataUsageChapter({ title, text }: DataUsageChapterProps) {
  const { t } = useTranslation();
  return (
    <Stack spacing={1}>
      <Typography level="title-sm">{title}</Typography>
      {/*eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing*/}
      <Typography level="body-sm">{text || t('no-information')}</Typography>
    </Stack>
  );
}
