import { Card, IconButton, Icons, Stack, Tooltip, Typography } from '@healthinal/ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PidBlockDto } from '../../api/generated.ts';
import { StyledTable } from '../../common/components/StyledTable.tsx';
import { PidBlockDeleteModal } from './PidBlockDeleteModal.tsx';
import { PidBlockModal } from './PidBlockModal.tsx';

interface PidBlockListTableProps {
  pidBlocks: PidBlockDto[];
  isExistingPidBlock: (pid: string) => boolean;
}

export function PidBlockListTable({ pidBlocks, isExistingPidBlock }: PidBlockListTableProps) {
  const { t } = useTranslation();

  const [pidBlockToUpdate, setPidBlockToUpdate] = useState<PidBlockDto>();
  const [pidToDelete, setPidToDelete] = useState<string>();

  return (
    <Card variant="outlined" sx={{ padding: 0 }}>
      <StyledTable>
        <thead>
          <tr>
            <th style={{ width: '120px', verticalAlign: 'middle' }}>
              <Typography alignSelf="center" level="title-sm">
                {t('pid')}
              </Typography>
            </th>
            <th style={{ verticalAlign: 'middle' }}>
              <Typography alignSelf="center" level="title-sm">
                {t('settings.block-list.pid-comment')}
              </Typography>
            </th>
            <th style={{ width: '112px' }} />
          </tr>
        </thead>
        <tbody>
          {pidBlocks.map((pidBlock) => (
            <PidBlockListTableEntry
              pidBlock={pidBlock}
              setPidBlockToUpdate={setPidBlockToUpdate}
              setPidToDelete={setPidToDelete}
              key={pidBlock.pid}
            />
          ))}
        </tbody>
      </StyledTable>
      <PidBlockModal
        open={!!pidBlockToUpdate}
        close={() => setPidBlockToUpdate(undefined)}
        pidBlock={pidBlockToUpdate}
        isExistingPidBlock={isExistingPidBlock}
      />
      <PidBlockDeleteModal pid={pidToDelete} close={() => setPidToDelete(undefined)} />
    </Card>
  );
}

interface PidBlockListTableEntryProps {
  pidBlock: PidBlockDto;
  setPidBlockToUpdate: (pidBlock: PidBlockDto) => void;
  setPidToDelete: (pid: string) => void;
}

function PidBlockListTableEntry({ pidBlock, setPidBlockToUpdate, setPidToDelete }: PidBlockListTableEntryProps) {
  return (
    <tr>
      <td>
        <Tooltip title={pidBlock.pid}>
          <Typography alignSelf="center" level="body-sm" textColor="text.primary" overflow={'clip'} noWrap>
            {pidBlock.pid}
          </Typography>
        </Tooltip>
      </td>
      <td>
        <Typography alignSelf="center" level="body-sm" overflow="clip" noWrap>
          {pidBlock.comment}
        </Typography>
      </td>
      <td>
        <Stack direction="row" gap={2}>
          <IconButton size="sm" onClick={() => setPidBlockToUpdate(pidBlock)}>
            <Icons.EditOutlined fontSize="xl" />
          </IconButton>
          <IconButton size="sm" onClick={() => setPidToDelete(pidBlock.pid)}>
            <Icons.DeleteOutlined fontSize="xl" color="danger" />
          </IconButton>
        </Stack>
      </td>
    </tr>
  );
}
