import { Icons, Stack, Tooltip, Typography } from '@healthinal/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PortalScopeRequest } from '../api/generated.ts';
import { PermissionScope } from './PermissionScope.tsx';

interface PermissionsProps {
  requests: PortalScopeRequest[];
  grantedIds?: Set<string>;
  onGrantedIdsChange?: (grantedIds: Set<string>) => void;
  disabled?: boolean;
}

export function Permissions({ requests, grantedIds, onGrantedIdsChange, disabled }: PermissionsProps) {
  const { t } = useTranslation();

  const groupedRequests = useMemo(() => {
    const required: PortalScopeRequest[] = [];
    const optional: PortalScopeRequest[] = [];
    for (const request of requests) {
      const group = request.optional ? optional : required;
      group.push(request);
    }
    return { required, optional };
  }, [requests]);

  return (
    <Stack gap={4} flex={1}>
      <Stack gap={2}>
        <Typography level="body-sm">{t('required-permissions')}</Typography>
        {groupedRequests.required.map((request) => (
          <PermissionScope key={request.id} request={request} />
        ))}
      </Stack>
      {groupedRequests.optional.length > 0 && (
        <Stack gap={2}>
          <Stack direction="row" alignItems="center">
            <Typography level="body-sm">{t('optional-permissions')}</Typography>
            <Tooltip title={t('optional-permissions-info')} sx={{ maxWidth: 400 }}>
              <Icons.InfoOutlined sx={{ width: 16, ml: 1 }} />
            </Tooltip>
          </Stack>
          {groupedRequests.optional.map((request) => (
            <PermissionScope
              key={request.id}
              request={request}
              granted={grantedIds?.has(request.id)}
              onGrantedChange={(granted) => {
                const newGrantedIds = new Set(grantedIds);
                if (granted) {
                  newGrantedIds.add(request.id);
                } else {
                  newGrantedIds.delete(request.id);
                }
                onGrantedIdsChange?.(newGrantedIds);
              }}
              readOnly={grantedIds === undefined}
              disabled={disabled}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
}
