import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import de from './de.json';

const resources = {
  de: {
    translation: de,
  },
};

export const supportedLanguages = Object.keys(resources);

export function initI18next() {
  void i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      resources,
      supportedLngs: supportedLanguages,
      fallbackLng: 'de',

      detection: {
        order: ['localStorage', 'navigator'],
        lookupLocalStorage: 'language',
      },
    });
}
