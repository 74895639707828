import 'photoswipe/dist/photoswipe.css';
import { ImgHTMLAttributes, ReactNode, useState } from 'react';
import { Gallery as Photoswipe, Item as PhotoswipeItem } from 'react-photoswipe-gallery';

interface LightboxProps {
  onSelect?: (index: number) => void;
  children?: ReactNode;
}

const padding = 64;
const animationDuration = 200;

export function Lightbox({ onSelect, children }: LightboxProps) {
  return (
    <Photoswipe
      options={{
        padding: { top: padding, bottom: padding, left: padding, right: padding },
        showAnimationDuration: animationDuration,
        hideAnimationDuration: animationDuration,
        zoomAnimationDuration: animationDuration,
        loop: false,
      }}
      onBeforeOpen={(instance) => {
        instance.on('change', () => {
          onSelect?.(instance.currIndex);
        });
      }}>
      {children}
    </Photoswipe>
  );
}

type LightboxImageProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'onClick' | 'onLoad'>;

export function LightboxImage({ src, alt, ...imgProps }: LightboxImageProps) {
  const [width, setWidth] = useState<number | undefined>();
  const [height, setHeight] = useState<number | undefined>();

  return (
    <PhotoswipeItem original={src} thumbnail={src} width={width} height={height} alt={alt}>
      {({ ref, open }) => (
        <img
          {...imgProps}
          src={src}
          alt={alt}
          ref={ref}
          onClick={open}
          onLoad={(event) => {
            // photoswipe needs to know the dimensions of the image
            setWidth(event.currentTarget.naturalWidth);
            setHeight(event.currentTarget.naturalHeight);
          }}
        />
      )}
    </PhotoswipeItem>
  );
}
