import { Dropdown, Menu, MenuItem } from '@healthinal/ui';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWhoamiSuspense } from '../../api/generated.ts';
import { HubspotFormDialog } from './HubspotForm.tsx';
import { useSupportForm } from './SupportForm.tsx';

export function HelpMenu({ children }: { children: ReactNode }) {
  const { t } = useTranslation();
  const { data: whoami } = useWhoamiSuspense();

  const openSupportForm = useSupportForm();
  const [openFeedbackForm, setOpenFeedbackForm] = useState(false);

  return (
    <Dropdown>
      {children}
      <Menu placement="bottom-end">
        <MenuItem component="a" href="https://heureka.health/#faq" target="_blank">
          {t('frequently-asked-questions')}
        </MenuItem>
        <MenuItem onClick={openSupportForm}>{t('support')}</MenuItem>
        <MenuItem onClick={() => setOpenFeedbackForm(true)}>{t('give-feedback')}</MenuItem>
      </Menu>

      {openFeedbackForm && (
        <HubspotFormDialog
          onClose={() => setOpenFeedbackForm(false)}
          formId="74e6dceb-a3a6-438d-bc52-a2ff587823ff"
          formDefaultValues={{
            email: whoami.email,
            hrka_name: whoami.name,
            hrka_hcp: whoami.organization,
          }}
        />
      )}
    </Dropdown>
  );
}
