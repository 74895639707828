import { Alert, Stack, styled, Typography } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';
import { useWhoamiSuspense } from '../../api/generated.ts';
import { ResourceIcon } from '../../common/components/ResourceIcon.tsx';
import { PisBadge } from '../../pis/PisBadge.tsx';
import { DataFlowCard, DataFlowInfoIcon } from './DataFlowCard.tsx';

export function PisCard() {
  const { t } = useTranslation();
  const { data: whoami } = useWhoamiSuspense();
  return (
    <DataFlowCard
      gap={1}
      infoCardContent={
        <>
          <Typography level="body-sm" textColor="text.primary">
            {t('practice-data-info.introduction')}
          </Typography>
          <ResourceGrid marginY={2}>
            {whoami.pis?.supportedResourceTypes.map((resourceType) => (
              <Stack key={resourceType} alignItems="center" gap={1}>
                <ResourceIcon type={resourceType} size="md" />
                <Typography level="body-sm" textColor="text.secondary">
                  {t(`resource-type.name.${resourceType}`)}
                </Typography>
              </Stack>
            ))}
          </ResourceGrid>
          <Alert color="primary">{t('practice-data-info.alert')}</Alert>
        </>
      }>
      {whoami.pis && <PisBadge pis={whoami.pis.type} />}
      <Typography level="title-sm" textColor="text.secondary">
        {t('practice-data')}
      </Typography>
      <DataFlowInfoIcon />
    </DataFlowCard>
  );
}

const ResourceGrid = styled(Stack)({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
  gap: 24,
});
