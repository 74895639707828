import { Button, ButtonProps } from '@healthinal/ui';
import { LinkComponent, useLinkProps } from '@tanstack/react-router';
import { ComponentProps } from 'react';

export const TabLink = ({ children, ...props }: ComponentProps<LinkComponent<typeof Button>>) => {
  const linkProps = useLinkProps({
    replace: true,
    ...props,
    // @ts-expect-error activeProps has no generic type, hence variant is not allowed
    activeProps: { variant: 'soft', color: 'primary' },
    // @ts-expect-error inactiveProps has no generic type, hence variant is not allowed
    inactiveProps: { variant: 'plain', color: 'neutral' },
  }) as ButtonProps;

  return (
    <Button component="a" {...linkProps}>
      {children}
    </Button>
  );
};
