import { Button, DialogActions, DialogContent, DialogTitle, Modal, ModalClose, ModalDialog } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';
import { getReadBlockedPidsQueryKey, useDeleteBlockedPid } from '../../api/generated.ts';
import { queryClient } from '../../api/queryClient.ts';
import { FormErrorMessage } from '../../common/components/FormErrorMessage.tsx';
import { useHealthcareProviderId } from '../../user/useHealthcareProviderId.ts';

interface PidBlockDeleteModalProps {
  pid?: string;
  close: () => void;
}

export function PidBlockDeleteModal({ pid, close }: PidBlockDeleteModalProps) {
  const { t } = useTranslation();
  const healthcareProviderId = useHealthcareProviderId();
  const {
    mutateAsync: deleteBlockedPidMutate,
    error: deleteError,
    isPending: isDeletePending,
    reset: resetDeleteQuery,
  } = useDeleteBlockedPid({
    mutation: {
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: getReadBlockedPidsQueryKey(healthcareProviderId),
        });
      },
    },
  });

  const closeModal = () => {
    resetDeleteQuery();
    close();
  };

  const submit = async () => {
    // PID cannot be null, if modal is visible
    pid &&
      (await deleteBlockedPidMutate(
        { healthcareProviderId: healthcareProviderId, pid: pid },
        { onSuccess: closeModal },
      ));
  };

  return (
    <Modal open={!!pid} onClose={closeModal}>
      <ModalDialog minWidth="sm" maxWidth="sm">
        <DialogTitle>
          {t('settings.block-list.modals.delete-title')} <ModalClose />
        </DialogTitle>
        <DialogContent>
          {t('settings.block-list.modals.delete-description', { pid: pid })}
          <FormErrorMessage errorMessage={deleteError?.message} />
        </DialogContent>
        <DialogActions>
          <Button variant="solid" color="danger" onClick={submit} loading={isDeletePending}>
            {t('delete')}
          </Button>
          <Button variant="plain" onClick={closeModal} disabled={isDeletePending}>
            {t('cancel')}
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
}
