import { Stack, Typography } from '@healthinal/ui';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { useReadDataProjectsSuspense } from '../../api/generated.ts';
import { Welcome } from '../../dashboard/Welcome.tsx';
import { DataFlowVisualization } from '../../dashboard/visualization/DataFlowVisualization.tsx';
import { DataProjectsGrid } from '../../data-projects/DataProjectsGrid.tsx';
import { useHealthcareProviderId } from '../../user/useHealthcareProviderId.ts';

export const Route = createFileRoute('/_main/')({
  component: Index,
});

function Index() {
  const { t } = useTranslation();
  const healthcareProviderId = useHealthcareProviderId();
  const { data: grantedDataProjects } = useReadDataProjectsSuspense({
    grantedForHealthcareProviderId: healthcareProviderId,
  });
  return (
    <Stack gap={8} paddingTop={4}>
      {grantedDataProjects.length === 0 && <Welcome />}

      <DataFlowVisualization grantedDataProjects={grantedDataProjects} />

      {grantedDataProjects.length > 0 && (
        <Stack gap={2}>
          <Typography level="title-md">{t('granted-data-projects.title')}</Typography>
          <DataProjectsGrid dataProjects={grantedDataProjects} />
        </Stack>
      )}
    </Stack>
  );
}
