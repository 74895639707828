import { router } from '../../main.tsx';

export interface HardNavigateOptions {
  /** Save current location to be restored by navigating to /continue */
  saveContinueHref?: boolean;
}

const CONTINUE_HREF_KEY = 'continue';

export function hardNavigate(url: string | URL, options: HardNavigateOptions = {}) {
  if (options.saveContinueHref) {
    sessionStorage.setItem(CONTINUE_HREF_KEY, router.state.location.href);
  }
  window.location.href = url.toString();
}

export function getAndClearContinueHref() {
  const continueUrl = sessionStorage.getItem(CONTINUE_HREF_KEY);
  sessionStorage.removeItem(CONTINUE_HREF_KEY);
  return continueUrl;
}
