import { Button, Icons, Stack, Typography } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';

export function WhatIsHeureka() {
  const { t } = useTranslation();

  return (
    <Stack direction="column" gap={3}>
      <Typography level="h3" color="primary">
        {t('what-is-heureka.title')}
      </Typography>
      <Stack gap={2}>
        <Typography level="body-md" color="neutral">
          {t('what-is-heureka.part-one')}
        </Typography>
        <Typography level="body-md" color="neutral">
          {t('what-is-heureka.part-two')}
        </Typography>
        <Typography level="body-md" color="neutral">
          {t('what-is-heureka.part-three')}
        </Typography>
      </Stack>
      <Button
        component="a"
        size="sm"
        variant="soft"
        endDecorator={<Icons.OpenInNew />}
        sx={{ alignSelf: 'flex-start' }}
        href="https://heureka.health"
        target="_blank">
        {t('learn-more')}
      </Button>
    </Stack>
  );
}
