import { FormControl, FormControlProps, FormHelperText, FormLabel } from '@healthinal/ui';
import { ReactNode } from 'react';
import { FieldPath, FieldValues, UseControllerProps, UseControllerReturn, useController } from 'react-hook-form';

export interface ReactHookFormControlProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>
  extends UseControllerProps<TFieldValues, TName>,
    Pick<FormControlProps, 'sx'> {
  label?: ReactNode;
  helperText?: ReactNode;
  children: ReactNode | ((controller: UseControllerReturn<TFieldValues, TName>) => ReactNode);
  required?: boolean;
}

export function ReactHookFormControl<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  label,
  children,
  helperText,
  sx,
  required,
  ...controllerProps
}: ReactHookFormControlProps<TFieldValues, TName>) {
  const controller = useController(controllerProps);
  const helperTextContent = controller.fieldState.error?.message ?? helperText;
  return (
    <FormControl error={!!controller.fieldState.error} sx={sx} required={required} disabled={controllerProps.disabled}>
      {label && <FormLabel>{label}</FormLabel>}
      {typeof children === 'function' ? children(controller) : children}
      {helperTextContent && <FormHelperText>{helperTextContent}</FormHelperText>}
    </FormControl>
  );
}
