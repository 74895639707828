/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './__root'
import { Route as UnknownUserImport } from './unknown-user'
import { Route as ContinueImport } from './continue'
import { Route as AuthorizationImport } from './authorization'
import { Route as MainImport } from './_main'
import { Route as MainIndexImport } from './_main/index'
import { Route as AuthorizationUpdateImport } from './authorization/update'
import { Route as AuthorizationRevokeImport } from './authorization/revoke'
import { Route as AuthorizationInvalidRedirectUriImport } from './authorization/invalid-redirect-uri'
import { Route as AuthorizationGrantImport } from './authorization/grant'
import { Route as MainSettingsIndexImport } from './_main/settings/index'
import { Route as MainAppsIndexImport } from './_main/apps/index'
import { Route as MainAccessLogIndexImport } from './_main/access-log/index'
import { Route as MainSettingsPidblocklistImport } from './_main/settings/pidblocklist'
import { Route as MainAppsIdImport } from './_main/apps/$id'
import { Route as MainAppsIdIndexImport } from './_main/apps/$id/index'
import { Route as MainAppsIdInfoImport } from './_main/apps/$id/info'
import { Route as MainAppsIdControlCenterImport } from './_main/apps/$id/control-center'

// Create/Update Routes

const UnknownUserRoute = UnknownUserImport.update({
  id: '/unknown-user',
  path: '/unknown-user',
  getParentRoute: () => rootRoute,
} as any)

const ContinueRoute = ContinueImport.update({
  id: '/continue',
  path: '/continue',
  getParentRoute: () => rootRoute,
} as any)

const AuthorizationRoute = AuthorizationImport.update({
  id: '/authorization',
  path: '/authorization',
  getParentRoute: () => rootRoute,
} as any)

const MainRoute = MainImport.update({
  id: '/_main',
  getParentRoute: () => rootRoute,
} as any)

const MainIndexRoute = MainIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => MainRoute,
} as any)

const AuthorizationUpdateRoute = AuthorizationUpdateImport.update({
  id: '/update',
  path: '/update',
  getParentRoute: () => AuthorizationRoute,
} as any)

const AuthorizationRevokeRoute = AuthorizationRevokeImport.update({
  id: '/revoke',
  path: '/revoke',
  getParentRoute: () => AuthorizationRoute,
} as any)

const AuthorizationInvalidRedirectUriRoute =
  AuthorizationInvalidRedirectUriImport.update({
    id: '/invalid-redirect-uri',
    path: '/invalid-redirect-uri',
    getParentRoute: () => AuthorizationRoute,
  } as any)

const AuthorizationGrantRoute = AuthorizationGrantImport.update({
  id: '/grant',
  path: '/grant',
  getParentRoute: () => AuthorizationRoute,
} as any)

const MainSettingsIndexRoute = MainSettingsIndexImport.update({
  id: '/settings/',
  path: '/settings/',
  getParentRoute: () => MainRoute,
} as any)

const MainAppsIndexRoute = MainAppsIndexImport.update({
  id: '/apps/',
  path: '/apps/',
  getParentRoute: () => MainRoute,
} as any)

const MainAccessLogIndexRoute = MainAccessLogIndexImport.update({
  id: '/access-log/',
  path: '/access-log/',
  getParentRoute: () => MainRoute,
} as any)

const MainSettingsPidblocklistRoute = MainSettingsPidblocklistImport.update({
  id: '/settings/pidblocklist',
  path: '/settings/pidblocklist',
  getParentRoute: () => MainRoute,
} as any)

const MainAppsIdRoute = MainAppsIdImport.update({
  id: '/apps/$id',
  path: '/apps/$id',
  getParentRoute: () => MainRoute,
} as any)

const MainAppsIdIndexRoute = MainAppsIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => MainAppsIdRoute,
} as any)

const MainAppsIdInfoRoute = MainAppsIdInfoImport.update({
  id: '/info',
  path: '/info',
  getParentRoute: () => MainAppsIdRoute,
} as any)

const MainAppsIdControlCenterRoute = MainAppsIdControlCenterImport.update({
  id: '/control-center',
  path: '/control-center',
  getParentRoute: () => MainAppsIdRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_main': {
      id: '/_main'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof MainImport
      parentRoute: typeof rootRoute
    }
    '/authorization': {
      id: '/authorization'
      path: '/authorization'
      fullPath: '/authorization'
      preLoaderRoute: typeof AuthorizationImport
      parentRoute: typeof rootRoute
    }
    '/continue': {
      id: '/continue'
      path: '/continue'
      fullPath: '/continue'
      preLoaderRoute: typeof ContinueImport
      parentRoute: typeof rootRoute
    }
    '/unknown-user': {
      id: '/unknown-user'
      path: '/unknown-user'
      fullPath: '/unknown-user'
      preLoaderRoute: typeof UnknownUserImport
      parentRoute: typeof rootRoute
    }
    '/authorization/grant': {
      id: '/authorization/grant'
      path: '/grant'
      fullPath: '/authorization/grant'
      preLoaderRoute: typeof AuthorizationGrantImport
      parentRoute: typeof AuthorizationImport
    }
    '/authorization/invalid-redirect-uri': {
      id: '/authorization/invalid-redirect-uri'
      path: '/invalid-redirect-uri'
      fullPath: '/authorization/invalid-redirect-uri'
      preLoaderRoute: typeof AuthorizationInvalidRedirectUriImport
      parentRoute: typeof AuthorizationImport
    }
    '/authorization/revoke': {
      id: '/authorization/revoke'
      path: '/revoke'
      fullPath: '/authorization/revoke'
      preLoaderRoute: typeof AuthorizationRevokeImport
      parentRoute: typeof AuthorizationImport
    }
    '/authorization/update': {
      id: '/authorization/update'
      path: '/update'
      fullPath: '/authorization/update'
      preLoaderRoute: typeof AuthorizationUpdateImport
      parentRoute: typeof AuthorizationImport
    }
    '/_main/': {
      id: '/_main/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof MainIndexImport
      parentRoute: typeof MainImport
    }
    '/_main/apps/$id': {
      id: '/_main/apps/$id'
      path: '/apps/$id'
      fullPath: '/apps/$id'
      preLoaderRoute: typeof MainAppsIdImport
      parentRoute: typeof MainImport
    }
    '/_main/settings/pidblocklist': {
      id: '/_main/settings/pidblocklist'
      path: '/settings/pidblocklist'
      fullPath: '/settings/pidblocklist'
      preLoaderRoute: typeof MainSettingsPidblocklistImport
      parentRoute: typeof MainImport
    }
    '/_main/access-log/': {
      id: '/_main/access-log/'
      path: '/access-log'
      fullPath: '/access-log'
      preLoaderRoute: typeof MainAccessLogIndexImport
      parentRoute: typeof MainImport
    }
    '/_main/apps/': {
      id: '/_main/apps/'
      path: '/apps'
      fullPath: '/apps'
      preLoaderRoute: typeof MainAppsIndexImport
      parentRoute: typeof MainImport
    }
    '/_main/settings/': {
      id: '/_main/settings/'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof MainSettingsIndexImport
      parentRoute: typeof MainImport
    }
    '/_main/apps/$id/control-center': {
      id: '/_main/apps/$id/control-center'
      path: '/control-center'
      fullPath: '/apps/$id/control-center'
      preLoaderRoute: typeof MainAppsIdControlCenterImport
      parentRoute: typeof MainAppsIdImport
    }
    '/_main/apps/$id/info': {
      id: '/_main/apps/$id/info'
      path: '/info'
      fullPath: '/apps/$id/info'
      preLoaderRoute: typeof MainAppsIdInfoImport
      parentRoute: typeof MainAppsIdImport
    }
    '/_main/apps/$id/': {
      id: '/_main/apps/$id/'
      path: '/'
      fullPath: '/apps/$id/'
      preLoaderRoute: typeof MainAppsIdIndexImport
      parentRoute: typeof MainAppsIdImport
    }
  }
}

// Create and export the route tree

interface MainAppsIdRouteChildren {
  MainAppsIdControlCenterRoute: typeof MainAppsIdControlCenterRoute
  MainAppsIdInfoRoute: typeof MainAppsIdInfoRoute
  MainAppsIdIndexRoute: typeof MainAppsIdIndexRoute
}

const MainAppsIdRouteChildren: MainAppsIdRouteChildren = {
  MainAppsIdControlCenterRoute: MainAppsIdControlCenterRoute,
  MainAppsIdInfoRoute: MainAppsIdInfoRoute,
  MainAppsIdIndexRoute: MainAppsIdIndexRoute,
}

const MainAppsIdRouteWithChildren = MainAppsIdRoute._addFileChildren(
  MainAppsIdRouteChildren,
)

interface MainRouteChildren {
  MainIndexRoute: typeof MainIndexRoute
  MainAppsIdRoute: typeof MainAppsIdRouteWithChildren
  MainSettingsPidblocklistRoute: typeof MainSettingsPidblocklistRoute
  MainAccessLogIndexRoute: typeof MainAccessLogIndexRoute
  MainAppsIndexRoute: typeof MainAppsIndexRoute
  MainSettingsIndexRoute: typeof MainSettingsIndexRoute
}

const MainRouteChildren: MainRouteChildren = {
  MainIndexRoute: MainIndexRoute,
  MainAppsIdRoute: MainAppsIdRouteWithChildren,
  MainSettingsPidblocklistRoute: MainSettingsPidblocklistRoute,
  MainAccessLogIndexRoute: MainAccessLogIndexRoute,
  MainAppsIndexRoute: MainAppsIndexRoute,
  MainSettingsIndexRoute: MainSettingsIndexRoute,
}

const MainRouteWithChildren = MainRoute._addFileChildren(MainRouteChildren)

interface AuthorizationRouteChildren {
  AuthorizationGrantRoute: typeof AuthorizationGrantRoute
  AuthorizationInvalidRedirectUriRoute: typeof AuthorizationInvalidRedirectUriRoute
  AuthorizationRevokeRoute: typeof AuthorizationRevokeRoute
  AuthorizationUpdateRoute: typeof AuthorizationUpdateRoute
}

const AuthorizationRouteChildren: AuthorizationRouteChildren = {
  AuthorizationGrantRoute: AuthorizationGrantRoute,
  AuthorizationInvalidRedirectUriRoute: AuthorizationInvalidRedirectUriRoute,
  AuthorizationRevokeRoute: AuthorizationRevokeRoute,
  AuthorizationUpdateRoute: AuthorizationUpdateRoute,
}

const AuthorizationRouteWithChildren = AuthorizationRoute._addFileChildren(
  AuthorizationRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof MainRouteWithChildren
  '/authorization': typeof AuthorizationRouteWithChildren
  '/continue': typeof ContinueRoute
  '/unknown-user': typeof UnknownUserRoute
  '/authorization/grant': typeof AuthorizationGrantRoute
  '/authorization/invalid-redirect-uri': typeof AuthorizationInvalidRedirectUriRoute
  '/authorization/revoke': typeof AuthorizationRevokeRoute
  '/authorization/update': typeof AuthorizationUpdateRoute
  '/': typeof MainIndexRoute
  '/apps/$id': typeof MainAppsIdRouteWithChildren
  '/settings/pidblocklist': typeof MainSettingsPidblocklistRoute
  '/access-log': typeof MainAccessLogIndexRoute
  '/apps': typeof MainAppsIndexRoute
  '/settings': typeof MainSettingsIndexRoute
  '/apps/$id/control-center': typeof MainAppsIdControlCenterRoute
  '/apps/$id/info': typeof MainAppsIdInfoRoute
  '/apps/$id/': typeof MainAppsIdIndexRoute
}

export interface FileRoutesByTo {
  '/authorization': typeof AuthorizationRouteWithChildren
  '/continue': typeof ContinueRoute
  '/unknown-user': typeof UnknownUserRoute
  '/authorization/grant': typeof AuthorizationGrantRoute
  '/authorization/invalid-redirect-uri': typeof AuthorizationInvalidRedirectUriRoute
  '/authorization/revoke': typeof AuthorizationRevokeRoute
  '/authorization/update': typeof AuthorizationUpdateRoute
  '/': typeof MainIndexRoute
  '/settings/pidblocklist': typeof MainSettingsPidblocklistRoute
  '/access-log': typeof MainAccessLogIndexRoute
  '/apps': typeof MainAppsIndexRoute
  '/settings': typeof MainSettingsIndexRoute
  '/apps/$id/control-center': typeof MainAppsIdControlCenterRoute
  '/apps/$id/info': typeof MainAppsIdInfoRoute
  '/apps/$id': typeof MainAppsIdIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_main': typeof MainRouteWithChildren
  '/authorization': typeof AuthorizationRouteWithChildren
  '/continue': typeof ContinueRoute
  '/unknown-user': typeof UnknownUserRoute
  '/authorization/grant': typeof AuthorizationGrantRoute
  '/authorization/invalid-redirect-uri': typeof AuthorizationInvalidRedirectUriRoute
  '/authorization/revoke': typeof AuthorizationRevokeRoute
  '/authorization/update': typeof AuthorizationUpdateRoute
  '/_main/': typeof MainIndexRoute
  '/_main/apps/$id': typeof MainAppsIdRouteWithChildren
  '/_main/settings/pidblocklist': typeof MainSettingsPidblocklistRoute
  '/_main/access-log/': typeof MainAccessLogIndexRoute
  '/_main/apps/': typeof MainAppsIndexRoute
  '/_main/settings/': typeof MainSettingsIndexRoute
  '/_main/apps/$id/control-center': typeof MainAppsIdControlCenterRoute
  '/_main/apps/$id/info': typeof MainAppsIdInfoRoute
  '/_main/apps/$id/': typeof MainAppsIdIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/authorization'
    | '/continue'
    | '/unknown-user'
    | '/authorization/grant'
    | '/authorization/invalid-redirect-uri'
    | '/authorization/revoke'
    | '/authorization/update'
    | '/'
    | '/apps/$id'
    | '/settings/pidblocklist'
    | '/access-log'
    | '/apps'
    | '/settings'
    | '/apps/$id/control-center'
    | '/apps/$id/info'
    | '/apps/$id/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/authorization'
    | '/continue'
    | '/unknown-user'
    | '/authorization/grant'
    | '/authorization/invalid-redirect-uri'
    | '/authorization/revoke'
    | '/authorization/update'
    | '/'
    | '/settings/pidblocklist'
    | '/access-log'
    | '/apps'
    | '/settings'
    | '/apps/$id/control-center'
    | '/apps/$id/info'
    | '/apps/$id'
  id:
    | '__root__'
    | '/_main'
    | '/authorization'
    | '/continue'
    | '/unknown-user'
    | '/authorization/grant'
    | '/authorization/invalid-redirect-uri'
    | '/authorization/revoke'
    | '/authorization/update'
    | '/_main/'
    | '/_main/apps/$id'
    | '/_main/settings/pidblocklist'
    | '/_main/access-log/'
    | '/_main/apps/'
    | '/_main/settings/'
    | '/_main/apps/$id/control-center'
    | '/_main/apps/$id/info'
    | '/_main/apps/$id/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  MainRoute: typeof MainRouteWithChildren
  AuthorizationRoute: typeof AuthorizationRouteWithChildren
  ContinueRoute: typeof ContinueRoute
  UnknownUserRoute: typeof UnknownUserRoute
}

const rootRouteChildren: RootRouteChildren = {
  MainRoute: MainRouteWithChildren,
  AuthorizationRoute: AuthorizationRouteWithChildren,
  ContinueRoute: ContinueRoute,
  UnknownUserRoute: UnknownUserRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_main",
        "/authorization",
        "/continue",
        "/unknown-user"
      ]
    },
    "/_main": {
      "filePath": "_main.tsx",
      "children": [
        "/_main/",
        "/_main/apps/$id",
        "/_main/settings/pidblocklist",
        "/_main/access-log/",
        "/_main/apps/",
        "/_main/settings/"
      ]
    },
    "/authorization": {
      "filePath": "authorization.tsx",
      "children": [
        "/authorization/grant",
        "/authorization/invalid-redirect-uri",
        "/authorization/revoke",
        "/authorization/update"
      ]
    },
    "/continue": {
      "filePath": "continue.tsx"
    },
    "/unknown-user": {
      "filePath": "unknown-user.tsx"
    },
    "/authorization/grant": {
      "filePath": "authorization/grant.tsx",
      "parent": "/authorization"
    },
    "/authorization/invalid-redirect-uri": {
      "filePath": "authorization/invalid-redirect-uri.tsx",
      "parent": "/authorization"
    },
    "/authorization/revoke": {
      "filePath": "authorization/revoke.tsx",
      "parent": "/authorization"
    },
    "/authorization/update": {
      "filePath": "authorization/update.tsx",
      "parent": "/authorization"
    },
    "/_main/": {
      "filePath": "_main/index.tsx",
      "parent": "/_main"
    },
    "/_main/apps/$id": {
      "filePath": "_main/apps/$id.tsx",
      "parent": "/_main",
      "children": [
        "/_main/apps/$id/control-center",
        "/_main/apps/$id/info",
        "/_main/apps/$id/"
      ]
    },
    "/_main/settings/pidblocklist": {
      "filePath": "_main/settings/pidblocklist.tsx",
      "parent": "/_main"
    },
    "/_main/access-log/": {
      "filePath": "_main/access-log/index.tsx",
      "parent": "/_main"
    },
    "/_main/apps/": {
      "filePath": "_main/apps/index.tsx",
      "parent": "/_main"
    },
    "/_main/settings/": {
      "filePath": "_main/settings/index.tsx",
      "parent": "/_main"
    },
    "/_main/apps/$id/control-center": {
      "filePath": "_main/apps/$id/control-center.tsx",
      "parent": "/_main/apps/$id"
    },
    "/_main/apps/$id/info": {
      "filePath": "_main/apps/$id/info.tsx",
      "parent": "/_main/apps/$id"
    },
    "/_main/apps/$id/": {
      "filePath": "_main/apps/$id/index.tsx",
      "parent": "/_main/apps/$id"
    }
  }
}
ROUTE_MANIFEST_END */
