import { createFileRoute, Navigate } from '@tanstack/react-router';
import { useGetGrantsOfDataProjectSuspense } from '../../../../api/generated.ts';
import { useHealthcareProviderId } from '../../../../user/useHealthcareProviderId.ts';

export const Route = createFileRoute('/_main/apps/$id/')({
  component: DataProjectIndex,
});

function DataProjectIndex() {
  const { id } = Route.useParams();
  const healthcareProviderId = useHealthcareProviderId();
  const { data: grants } = useGetGrantsOfDataProjectSuspense(id, { healthcareProviderId });

  return <Navigate from={Route.fullPath} to={grants.dataProjectGranted ? 'control-center' : 'info'} replace />;
}
