import { Button, DialogContent, Modal, ModalDialog } from '@healthinal/ui';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RevokeGrantSuccess, RevokeGrantSuccessProps } from './RevokeGrantSuccess.tsx';
import { RevokeGrantSuccessContext } from './RevokeGrantSuccessContext.tsx';

interface RevokeGrantSuccessProviderProps extends RevokeGrantSuccessProps {
  children: ReactNode;
}

export function RevokeGrantSuccessProvider({ children, ...revokeGrantSuccessProps }: RevokeGrantSuccessProviderProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <RevokeGrantSuccessContext.Provider value={() => setOpen(true)}>
      {children}
      {open && (
        <Modal open onClose={() => setOpen(false)}>
          <ModalDialog maxWidth={400}>
            <DialogContent>
              <RevokeGrantSuccess {...revokeGrantSuccessProps} />
            </DialogContent>
            <Button onClick={() => setOpen(false)} sx={{ marginTop: 3 }}>
              {t('close')}
            </Button>
          </ModalDialog>
        </Modal>
      )}
    </RevokeGrantSuccessContext.Provider>
  );
}
