import { Alert, Avatar, Icons, Stack } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';
import { PortalDataProject } from '../api/generated.ts';
import { AuthorizationStatusPanel } from './AuthorizationStatusPanel.tsx';
import { DataProjectSupportLinks } from './DataProjectSupportLinks.tsx';

export interface RevokeGrantSuccessProps {
  dataProject: PortalDataProject;
}

export function RevokeGrantSuccess({ dataProject }: RevokeGrantSuccessProps) {
  const { t } = useTranslation();

  return (
    <AuthorizationStatusPanel
      decoration={
        <Avatar color="success" size="lg">
          <Icons.Check />
        </Avatar>
      }
      title={t('grant-removal.successful')}
      body={t('grant-removal.delay-notice')}>
      <Alert variant="soft" color="primary" startDecorator={<Icons.InfoOutlined />} sx={{ alignItems: 'flex-start' }}>
        <Stack gap={1}>
          {t('grant-removal.licence-notice', { provider: dataProject.provider })}
          <DataProjectSupportLinks dataProject={dataProject} />
        </Stack>
      </Alert>
    </AuthorizationStatusPanel>
  );
}
