import { Alert, Icons } from '@healthinal/ui';

export function FormErrorMessage({ errorMessage }: { errorMessage?: string }) {
  return (
    errorMessage && (
      <Alert color="danger" variant="soft" sx={{ marginY: 2 }} startDecorator={<Icons.ErrorOutline />}>
        {errorMessage}
      </Alert>
    )
  );
}
