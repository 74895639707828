import { Alert } from '@healthinal/ui';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { useReadDataProjectsSuspense } from '../../../api/generated.ts';
import { DataProjectsGrid } from '../../../data-projects/DataProjectsGrid.tsx';

export const Route = createFileRoute('/_main/apps/')({
  component: Apps,
});

function Apps() {
  const { t } = useTranslation();
  const { data: dataProjects } = useReadDataProjectsSuspense();
  if (dataProjects.length == 0) {
    return <Alert>{t('data-projects.empty')}</Alert>;
  }
  return <DataProjectsGrid dataProjects={dataProjects} showDescription showGrantState />;
}
