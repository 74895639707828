import { Box } from '@healthinal/ui';
import { HeurekaSpinner } from './HeurekaSpinner.tsx';

export function SplashScreen() {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
      <HeurekaSpinner size="lg" />
    </Box>
  );
}
