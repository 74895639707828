import { Button, Card, Icons, Stack, styled, Typography } from '@healthinal/ui';
import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PortalDataProject, useReadDataProjectSuspense, useWhoamiSuspense } from '../../../../api/generated.ts';
import { Gallery } from '../../../../common/components/Gallery.tsx';
import { HubspotFormDialog } from '../../../../common/components/HubspotForm.tsx';
import { DataUsageCard } from '../../../../data-projects/DataUsageCard.tsx';
import { Permissions } from '../../../../data-projects/Permissions.tsx';

export const Route = createFileRoute('/_main/apps/$id/info')({
  component: DataProjectInfo,
});

function DataProjectInfo() {
  const { t } = useTranslation();
  const { id } = Route.useParams();
  const { data: dataProject } = useReadDataProjectSuspense(id);
  return (
    <>
      <Stack direction="row" gap={8} alignItems="flex-start">
        <Typography whiteSpace="pre-wrap" level="body-sm" textColor="text.secondary">
          {dataProject.descriptionLong}
        </Typography>
        <LeadCard dataProject={dataProject} />
      </Stack>

      <Gallery images={dataProject.previews.map((preview) => ({ src: preview.preview, alt: preview.description }))} />

      <Stack gap={3}>
        <Typography level="title-lg" textAlign="center">
          {t('permissions-and-data-usage')}
        </Typography>
        <Grid>
          {dataProject.scopes.length > 0 && (
            <Card size="lg">
              <Permissions requests={dataProject.scopes} />
            </Card>
          )}
          <DataUsageCard dataProject={dataProject} />
        </Grid>
      </Stack>
    </>
  );
}

const Grid = styled('div')({
  display: 'grid',
  gap: 24,
  gridTemplateColumns: '1fr 1fr',
});

interface LeadCardProps {
  dataProject: PortalDataProject;
}

function LeadCard({ dataProject }: LeadCardProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { data: whoami } = useWhoamiSuspense();
  return (
    <Stack gap={2}>
      <Card variant="soft" sx={{ width: 240, flexShrink: 0, padding: 3, gap: 2 }}>
        <Typography level="body-sm" textColor="text.primary">
          {t('data-project-lead.question', { dataProject: dataProject.name })}
        </Typography>
        <Button onClick={() => setOpen(true)}>{t('data-project-lead.get-in-touch')}</Button>
        {open && (
          <HubspotFormDialog
            onClose={() => setOpen(false)}
            formId="559b0afa-81fc-4c22-bac2-240a6694ffaa"
            formDefaultValues={{
              email: whoami.email,
              hrka_name: whoami.name,
              hrka_hcp: whoami.organization,
              hrka_service: dataProject.name,
            }}
          />
        )}
      </Card>
      {dataProject.homepage && (
        <Button
          variant="plain"
          endDecorator={<Icons.Launch />}
          component="a"
          href={dataProject.homepage}
          target={'_blank'}>
          {t('data-project-website')}
        </Button>
      )}
    </Stack>
  );
}
