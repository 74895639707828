import { IconButton, Icons, styled } from '@healthinal/ui';
import useEmblaCarousel from 'embla-carousel-react';
import { useEffect, useState } from 'react';
import { theme } from '../../theme.ts';
import { hoverShadow } from '../styles/hoverShadow.ts';
import { Lightbox, LightboxImage } from './Lightbox.tsx';

interface GalleryImage {
  src: string;
  alt: string;
}

interface GalleryProps {
  images: GalleryImage[];
}

export function Gallery({ images }: GalleryProps) {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    duration: 20,
    slidesToScroll: 'auto',
    inViewThreshold: 1,
  });

  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);

  useEffect(() => {
    if (!emblaApi) return;

    const updateState = () => {
      setCanScrollPrev(emblaApi.canScrollPrev());
      setCanScrollNext(emblaApi.canScrollNext());
    };

    emblaApi.on('select', updateState);
    emblaApi.on('reInit', updateState);
    emblaApi.on('init', updateState);
  }, [emblaApi]);

  const scrollToSnapBySlideIndex = (slideIndex: number) => {
    if (!emblaApi) return;
    const { slideRegistry } = emblaApi.internalEngine();
    const snapIndex = slideRegistry.findIndex((snap) => snap.includes(slideIndex));
    emblaApi.scrollTo(snapIndex);
  };

  if (images.length === 0) {
    return null;
  }

  return (
    <Viewport>
      <Carousel ref={emblaRef}>
        <CarouselContent>
          <Lightbox onSelect={(index) => scrollToSnapBySlideIndex(index)}>
            {images.map((image, index) => (
              <StyledLightboxImage key={index} src={image.src} alt={image.alt} />
            ))}
          </Lightbox>
        </CarouselContent>
      </Carousel>
      <LeftFadeOut>
        {canScrollPrev && (
          <ScrollButton size="lg" variant="soft" color="primary" onClick={() => emblaApi?.scrollPrev()}>
            <Icons.ChevronLeft />
          </ScrollButton>
        )}
      </LeftFadeOut>
      <RightFadeOut>
        {canScrollNext && (
          <ScrollButton size="lg" variant="soft" color="primary" onClick={() => emblaApi?.scrollNext()}>
            <Icons.ChevronRight />
          </ScrollButton>
        )}
      </RightFadeOut>
    </Viewport>
  );
}

const fadeOutWidth = 96;

const Viewport = styled('div')({
  position: 'relative',
  // let the fade-out go beyond regular content width, unless it would be wider than the browser viewport
  margin: `0 calc(max((100% - 100vw) / 2, -${fadeOutWidth}px))`,
  // make scroll buttons more subtle while the user is not hovering over the gallery
  ':not(:hover) button': {
    background: 'none',
  },
});

const Carousel = styled('div')({
  overflow: 'hidden',
});

const CarouselContent = styled('div')({
  display: 'flex',
  margin: `24px ${fadeOutWidth}px`, // vertical margin to ensure that the hover shadow is not cut off
  gap: 16,
});

const StyledLightboxImage = styled(LightboxImage)({
  borderRadius: theme.vars.radius.md,
  border: `solid 1px ${theme.vars.palette.divider}`,
  cursor: 'zoom-in',
  height: 280,
  ...hoverShadow,
});

const FadeOut = styled('div')({
  position: 'absolute',
  right: 0,
  top: 0,
  bottom: 0,
  width: fadeOutWidth,
  display: 'flex',
  alignItems: 'center',
});

const fadeOutGradient = (angle: string) =>
  `linear-gradient(${angle}, ${theme.vars.palette.background.body} 20%, transparent 100%)`;

const LeftFadeOut = styled(FadeOut)({
  left: 0,
  background: fadeOutGradient('90deg'),
  justifyContent: 'flex-start',
});

const RightFadeOut = styled(FadeOut)({
  right: 0,
  background: fadeOutGradient('-90deg'),
  justifyContent: 'flex-end',
});

const ScrollButton = styled(IconButton)({
  transition: 'background 400ms',
  backdropFilter: 'blur(2px)', // blur background slightly to improve visibility
});
