import { useLocation } from '@tanstack/react-router';
import { PostHogProvider as PostHogSdkProvider, usePostHog } from 'posthog-js/react';
import { ReactNode, useEffect } from 'react';
import { useWhoami } from '../../api/generated.ts';
import { environment } from '../utils/environment.ts';

interface PostHogProviderProps {
  children: ReactNode;
}

const apiKey =
  environment === 'production'
    ? 'phc_KFjDaiyL0VBtJTnYTGXC973caPqYDcNSE8QJnHuWlLd'
    : 'phc_M1qAtVj3hx11YFOr4CobnraW2U5VCuwggIArvleK4i5';

export function PostHogProvider({ children }: PostHogProviderProps) {
  return (
    <PostHogSdkProvider
      apiKey={apiKey}
      options={{
        api_host: '/ph', // requests are proxied by our nginx to circumvent adblockers
        ui_host: 'https://eu.posthog.com', // for the toolbar (noted here https://posthog.com/docs/advanced/proxy)
        capture_pageview: false, // pageviews are captured manually below to support client-side routing
      }}>
      <PostHogInit />
      {children}
    </PostHogSdkProvider>
  );
}

function PostHogInit() {
  const posthog = usePostHog();

  const location = useLocation();
  useEffect(() => {
    posthog.capture('$pageview');
  }, [posthog, location.pathname]);

  const { data: whoami, isPending } = useWhoami({ query: { throwOnError: false } });
  useEffect(() => {
    if (isPending) return;

    if (whoami?.userId) {
      posthog.identify(whoami.userId);
    } else if (posthog._isIdentified()) {
      posthog.reset();
    }
  }, [posthog, whoami, isPending]);

  return null;
}
