import { Typography } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';
import { useWhoamiSuspense } from '../../api/generated.ts';
import { HeurekaSpinner } from '../../common/components/HeurekaSpinner.tsx';
import { DataFlowCard, DataFlowInfoIcon } from './DataFlowCard.tsx';
import { flowAnimationInterval } from './FlowAnimation.tsx';

export function HeurekaConnectorCard() {
  const { t } = useTranslation();
  const { data: whoami } = useWhoamiSuspense();
  return (
    <DataFlowCard
      infoCardContent={
        <>
          <Typography level="body-sm" textColor="text.secondary">
            {t('heureka-connector-info.location', {
              context: whoami.pis?.implementationType === 'HTTP' ? 'cloud' : 'onprem',
            })}{' '}
            {t('heureka-connector-info.purpose')}
          </Typography>
          <Typography level="body-sm" textColor="text.secondary">
            {t('heureka-connector-info.portal')}
          </Typography>
        </>
      }>
      <HeurekaSpinner loopInterval={flowAnimationInterval} />
      <Typography level="title-sm" textColor="text.secondary">
        {t('heureka-connector')}
      </Typography>
      <DataFlowInfoIcon sx={{ marginTop: 1 }} />
    </DataFlowCard>
  );
}
